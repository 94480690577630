// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:dd266d52-c8c4-4b82-b148-d84335ff9764",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_JhVwrgeGI",
    "aws_user_pools_web_client_id": "2ecd1olbs0iin6mfcj5pdkmurn",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://zpkt5nc5srbklke2lkhzzuifru.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "apirestmountainviewwebtech",
            "endpoint": "https://lbm3y8e1m9.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
