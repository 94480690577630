import React from 'react';

const classes: any = require('./Logo.module.scss');

const Logo = () => {
	return (
		<div className={classes.Logo}>
			<img className='d-md-inline-block' src={`/assets/img/logo-thumb.png`} alt='Logo' />
			<span className={classes.LogoTitle}>One Stop</span>
		</div>
	);
};

export default Logo;
