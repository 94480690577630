import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import Button, { ButtonProps as MatUIButtonProps } from '@material-ui/core/Button';
const classes: any = require('./Button.module.scss');

type ButtonInheritedProps = MatUIButtonProps & RouteComponentProps;
interface ButtonProps extends ButtonInheritedProps {
	disabled?: boolean;
	theme?: string;
	to?: string;
	click?: React.MouseEventHandler;
	children: JSX.Element | String;
	style?: React.CSSProperties;
	onClick?: () => void;
	type?: 'submit' | 'reset' | 'button';
}

const ButtonComponent = (props: ButtonProps) => {
	const {
		children,
		to,
		variant,
		size,
		color,
		className,
		history,
		onClick,
		type = 'button',
		disabled = false
	} = props;

	// create button elem
	const onClickHandler = () => {
		if (to) return history.push(to);
		return onClick && onClick();
	};

	return (
		<Button
			onClick={onClickHandler}
			className={className}
			variant={variant}
			size={size}
			color={color}
			type={type}
			disabled={disabled}
		>
			{children}
		</Button>
	);
};

export default withRouter(ButtonComponent);
