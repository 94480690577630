import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';

// vendor css
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';

// app
import App from './App';
import './index.scss';

// aws-amplify
import AWSExports from './aws-exports';
Amplify.configure(AWSExports);

// redux store
import Store from './store/store';

const app = (
	<Provider store={Store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root') as HTMLElement);
