import * as React from 'react';
import { withRouter } from 'react-router-dom';
import HeaderNav from './HeaderNav/HeaderNav';
import MobileNavMenu from './MobileNavMenu/MobileNavMenu';

class MainNavBar extends React.Component<any, any> {
	mobileNavAnimationTime = 350;

	state = {
		mobileNavMenu: {
			isHidden: true,
			opened: false,
			animating: false
		},
		navLinks: [
			{
				onClick: () => {
					const elem = document.querySelector('#About');
					elem && elem.scrollIntoView({ behavior: 'smooth' });
				},
				text: 'About',
				classes: 'btn btn-link'
			},
			{
				onClick: () => {
					const elem = document.querySelector('#Services');
					elem && elem.scrollIntoView({ behavior: 'smooth' });
				},
				text: 'Services',
				classes: 'btn btn-link'
			},
			{
				onClick: () => {
					const elem = document.querySelector('#Portfolio');
					elem && elem.scrollIntoView({ behavior: 'smooth' });
				},
				text: 'Portfolio',
				classes: 'btn btn-link'
			},
			{
				onClick: () => {
					const elem = document.querySelector('#Testimonials');
					elem && elem.scrollIntoView({ behavior: 'smooth' });
				},
				text: 'Testimonials',
				classes: 'btn btn-link'
			}
		]
	};

	linkClickedHandler = (newPath: string) => {
		if (this.state.mobileNavMenu.animating || !this.state.mobileNavMenu.opened) return;

		this.props.history.push(newPath);

		this.setState({
			mobileNavMenu: {
				isHidden: false,
				opened: false,
				animating: true
			}
		});

		setTimeout(() => {
			this.setState((prevState: any) => {
				return {
					mobileNavMenu: {
						...prevState.mobileNavMenu,
						animating: false
					}
				};
			});
		}, this.mobileNavAnimationTime);
	};

	mobileNavMenuToggleHandler = () => {
		if (this.state.mobileNavMenu.animating) return;

		this.setState((prevState: any) => {
			return {
				mobileNavMenu: {
					isHidden: false,
					opened: !prevState.mobileNavMenu.opened,
					animating: true
				}
			};
		});

		setTimeout(() => {
			this.setState((prevState: any) => {
				return {
					mobileNavMenu: {
						...prevState.mobileNavMenu,
						animating: false
					}
				};
			});
		}, this.mobileNavAnimationTime);
	};

	render() {
		return (
			<React.Fragment>
				<HeaderNav
					showMobileNav={this.state.mobileNavMenu.opened}
					navLinks={this.state.navLinks}
					mobileNavToggled={this.mobileNavMenuToggleHandler}
				/>

				<MobileNavMenu
					navLinks={this.state.navLinks}
					show={this.state.mobileNavMenu.opened}
					animating={this.state.mobileNavMenu.animating}
					mobileNavToggled={this.mobileNavMenuToggleHandler}
					isHidden={this.state.mobileNavMenu.isHidden}
					linkClicked={this.linkClickedHandler}
				/>
			</React.Fragment>
		);
	}
}

export default withRouter(MainNavBar);
