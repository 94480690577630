import * as React from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';
const classes: any = require('./index.module.scss');

interface TestimonialsSliderProps {}

const items = [
	{
		img: '/assets/img/clients/bardough.png',
		alt: 'Bardough'
	},
	{
		img: '/assets/img/clients/xylo.png',
		alt: 'Xylo'
	},
	{
		img: '/assets/img/clients/ab-book-keeping.png',
		alt: 'AB Book Keeping'
	},
	{
		img: '/assets/img/clients/neon-flamingo.png',
		alt: 'Neon Flamingo'
	},
	{
		img: '/assets/img/clients/planet-arcadia.png',
		alt: 'Planet Arcadia'
	}
];

const TestimonialsSlider: React.FC<TestimonialsSliderProps> = (props) => {
	const [ activeIndex, setActiveIndex ] = React.useState<number>(0);
	const [ animating, setAnimating ] = React.useState<boolean>(false);

	const next = () => {
		if (animating) return;
		const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
		setActiveIndex(nextIndex);
	};

	const previous = () => {
		if (animating) return;
		const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
		setActiveIndex(nextIndex);
	};

	const slides = items.map((item, index) => {
		return (
			<CarouselItem
				onExiting={() => setAnimating(true)}
				onExited={() => setAnimating(false)}
				key={index}
				className={classes.CarouselItem}
			>
				<div className={classes.ImgBox}>
					<img src={`${item.img}`} alt={`${item.alt}`} />
				</div>
			</CarouselItem>
		);
	});

	return (
		<Carousel
			pause={false}
			interval={2500}
			activeIndex={activeIndex}
			next={next}
			previous={previous}
			className={classes.Carousel}
		>
			{slides}
			<CarouselControl
				className={[ classes.CarouselControl, classes.CarouselLeft ].join(' ')}
				direction='prev'
				directionText='Previous'
				onClickHandler={previous}
			/>
			<CarouselControl
				className={[ classes.CarouselControl, classes.CarouselRight ].join(' ')}
				direction='next'
				directionText='Next'
				onClickHandler={next}
			/>
		</Carousel>
	);
};

export default TestimonialsSlider;
