import * as React from 'react';
import { API } from 'aws-amplify';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormControl, TextField, InputAdornment } from '@material-ui/core';
import Button from '../../../../components/UI/Button/Button';

import { Person, Email, PhoneAndroid } from '@material-ui/icons';

const ClassNames: any = require('./index.module.scss');

interface QuoteModalProps {
	showModal: boolean;
	closeModal: () => void;
	toggleModal: () => void;
	afterSuccessfulFormSubmit: () => void;
}

export interface QuoteRequestFormValues {
	name: string;
	email: string;
	phone: string;
	message: string;
}

const RequestQuoteFormValidationSchema = Yup.object().shape({
	name: Yup.string().required('Required'),
	email: Yup.string().email('Email provided is not valid.').required('Required'),
	phone: Yup.string()
		.min(10, 'Provide a 10 digit phone number that includes your area code')
		.max(10, 'Phone number provided has too many numbers')
		.required('Required'),
	message: Yup.string()
		.min(10, 'Minimum of 10 characters are required')
		.max(500, 'Maximum of 500 characters are allowed')
		.required('Required')
});

const QuoteModal: React.FC<QuoteModalProps> = (props) => {
	const { showModal, closeModal, toggleModal, afterSuccessfulFormSubmit } = props;
	const [ submittingQuoteRequest, setSubmittingQuoteRequest ] = React.useState<boolean>(false);

	const handleQuoteRequestFormSubmit = async (values: QuoteRequestFormValues) => {
		setSubmittingQuoteRequest(true);
		console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);

		try {
			if (process.env.NODE_ENV === 'development') {
				fetch('http://localhost:3001/submit-quote-request', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(values)
				});
			} else {
				await API.post('apirestmountainviewwebtech', '/submit-quote-request', {
					body: values
				});
			}

			setSubmittingQuoteRequest(false);
			closeModal();
			afterSuccessfulFormSubmit();
		} catch (err) {
			setSubmittingQuoteRequest(false);
		}
	};

	const renderSubmitButtonText = (): JSX.Element | String => {
		if (submittingQuoteRequest) {
			return (
				<span>
					<i className='fas fa-spinner fa-spin' /> &nbsp;Submitting..
				</span>
			);
		}

		return 'Submit Quote Request';
	};

	return (
		<Modal className={ClassNames.RequestQuoteModal} isOpen={showModal} toggle={toggleModal} backdrop={true}>
			<ModalHeader className={ClassNames.RequestQuoteModalHeader} toggle={toggleModal}>
				<p className='section-header text-uppercase mb-0'>IT Services</p>
				<span className='section-subheader'>Request a quote</span>
			</ModalHeader>
			<ModalBody className={[ 'container', ClassNames.RequestQuoteModalBody ].join(' ')}>
				<p className='mb-4'>
					Do you have a great idea for a website or mobile application and are you wondering how you can make
					your dream a reality? We're here to help. Fill out the form below and we'll get back to you as soon
					as we can. We'll schedule a meet and greet to discuss your project in more detail and then we'll get
					to work on your free quote. It's as easy as that!
				</p>

				<Formik
					initialValues={{
						name: '',
						email: '',
						phone: '',
						message: ''
					}}
					validationSchema={RequestQuoteFormValidationSchema}
					onSubmit={(values: QuoteRequestFormValues) => handleQuoteRequestFormSubmit(values)}
					render={(formikBag: FormikProps<QuoteRequestFormValues>) => (
						<Form className='' noValidate>
							{/* Name */}
							<div className='row align-items-center justify-content-center mb-4'>
								<div className='col'>
									<TextField
										className='w-100'
										id='name'
										type='text'
										label='Full Name'
										placeholder='John Doe'
										value={formikBag.values.name}
										onChange={formikBag.handleChange}
										onBlur={formikBag.handleBlur}
										variant='outlined'
										required
										error={!!formikBag.touched.name && !!formikBag.errors.name}
										helperText={
											formikBag.touched.name && !!formikBag.errors.name ? (
												formikBag.errors.name
											) : null
										}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<Person className={ClassNames.InputFieldIcon} />
												</InputAdornment>
											)
										}}
									/>
								</div>
							</div>

							{/* Email */}
							<div className='row align-items-center justify-content-center mb-4'>
								<div className='col'>
									<FormControl className='w-100'>
										<TextField
											id='email'
											type='text'
											label='Email Address'
											placeholder='janedoe@gmail.com'
											value={formikBag.values.email}
											onChange={formikBag.handleChange}
											onBlur={formikBag.handleBlur}
											variant='outlined'
											required
											error={!!formikBag.touched.email && !!formikBag.errors.email}
											helperText={
												formikBag.touched.email && !!formikBag.errors.email ? (
													formikBag.errors.email
												) : null
											}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<Email className={ClassNames.InputFieldIcon} />
													</InputAdornment>
												)
											}}
										/>
									</FormControl>
								</div>
							</div>

							{/* Phone */}
							<div className='row align-items-center justify-content-center mb-4'>
								<div className='col'>
									<FormControl className='w-100'>
										<TextField
											id='phone'
											type='tel'
											label='Phone Number'
											placeholder='6047923600'
											value={formikBag.values.phone}
											onChange={formikBag.handleChange}
											onBlur={formikBag.handleBlur}
											variant='outlined'
											required
											error={!!formikBag.touched.phone && !!formikBag.errors.phone}
											helperText={
												formikBag.touched.phone && !!formikBag.errors.phone ? (
													formikBag.errors.phone
												) : null
											}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<PhoneAndroid className={ClassNames.InputFieldIcon} />
													</InputAdornment>
												)
											}}
										/>
									</FormControl>
								</div>
							</div>

							{/* Message */}
							<div className='row align-items-center justify-content-center mb-4'>
								<div className='col'>
									<FormControl className='w-100'>
										<TextField
											id='message'
											type='message'
											label='Project Description'
											placeholder='Write a quick overview of your project..'
											value={formikBag.values.message}
											onChange={formikBag.handleChange}
											onBlur={formikBag.handleBlur}
											variant='outlined'
											required
											multiline
											rows='8'
											rowsMax='8'
											error={formikBag.touched.message && !!formikBag.errors.message}
											helperText={
												formikBag.touched.message && !!formikBag.errors.message ? (
													formikBag.errors.message
												) : null
											}
										/>
									</FormControl>
								</div>
							</div>

							<div className='text-center'>
								<Button
									type='submit'
									className='bg-brand-blue'
									color='primary'
									variant='contained'
									size='large'
									disabled={submittingQuoteRequest}
								>
									{renderSubmitButtonText()}
								</Button>
							</div>
						</Form>
					)}
				/>
			</ModalBody>
		</Modal>
	);
};

export default QuoteModal;
