import * as React from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';
const classes: any = require('./index.module.scss');

interface TestimonialsSliderProps {}

const items = [
	{
		testimonial:
			'Working with Alex is an absolute pleasure. Year after year, he continues to impress me with his ability to listen to our needs, recommend solutions and deliver on promises. Since working with One Stop, our daily takeout orders have gone through the roof!',
		starRating: 5,
		name: 'Paula Wilson',
		position: 'Owner',
		company: 'Bardough',
		img: '/assets/img/clients/paula-wilson.jpg'
	},
	{
		testimonial:
			"I've tried building a personal website for myself before and it did not go well. After working with One Stop Technologies, I now have a professional website that helps me advertise my business, which helps me find new gigs.",
		starRating: 4.5,
		name: 'Michael Holz',
		position: 'Musician',
		company: 'Xylo',
		img: '/assets/img/clients/michael-holz.jpg'
	},
	{
		testimonial:
			"I'm very happy with the mobile app that One Stop Technologies built for us. The ability for our customers to book our services online, manage those reservations and receive text/email alerts has had a significant impact on our reocurring revenue.",
		starRating: 5,
		name: 'Antonio Moreno',
		position: 'Business Development Manager',
		company: 'Roof & Casa',
		img: '/assets/img/clients/antonia-moreno.jpg'
	}
];

const TestimonialsSlider: React.FC<TestimonialsSliderProps> = (props) => {
	const [ activeIndex, setActiveIndex ] = React.useState<number>(0);
	const [ animating, setAnimating ] = React.useState<boolean>(false);

	const next = () => {
		if (animating) return;
		const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
		setActiveIndex(nextIndex);
	};

	const previous = () => {
		if (animating) return;
		const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
		setActiveIndex(nextIndex);
	};

	const renderStarRating = (starRating: number) => {
		const liElems = [];

		while (starRating > 0) {
			liElems.push(
				<li key={starRating} className='list-inline-item'>
					<i className='fas fa-star' />
				</li>
			);
			starRating--;
			if (starRating < 1 && starRating > 0) {
				liElems.push(
					<li key={starRating} className='list-inline-item'>
						<i className='fas fa-star-half-alt' />
					</li>
				);
				starRating = starRating - 0.5;
			}
		}

		return <ul className='list-inline'>{liElems}</ul>;
	};

	const slides = items.map((item, index) => {
		return (
			<CarouselItem
				onExiting={() => setAnimating(true)}
				onExited={() => setAnimating(false)}
				key={index}
				className={classes.CarouselItem}
			>
				<div className={classes.ImgBox}>
					<img src={`${item.img}`} alt={`${item.name}`} />
				</div>
				<p className={classes.Testimonial}>{item.testimonial}</p>
				<p className={classes.Overview}>
					<b>{item.name}</b> &mdash;{item.position} at {item.company}
				</p>
				<div className={classes.StarRating}>{renderStarRating(item.starRating)}</div>
			</CarouselItem>
		);
	});

	return (
		<Carousel
			interval={2500}
			activeIndex={activeIndex}
			next={next}
			previous={previous}
			className={classes.Carousel}
		>
			{slides}
			<CarouselControl
				className={[ classes.CarouselControl, classes.CarouselLeft ].join(' ')}
				direction='prev'
				directionText='Previous'
				onClickHandler={previous}
			/>
			<CarouselControl
				className={[ classes.CarouselControl, classes.CarouselRight ].join(' ')}
				direction='next'
				directionText='Next'
				onClickHandler={next}
			/>
		</Carousel>
	);
};

export default TestimonialsSlider;
