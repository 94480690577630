import * as React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from 'reactstrap';

const classes: any = require('./Home.module.scss');

// icons / imgs
import { ReactComponent as IconWebDesignSVG } from '../../assets/icon/web-design.svg';
import { ReactComponent as IconDatabaseSVG } from '../../assets/icon/database.svg';
import { ReactComponent as IconCloudComputeSVG } from '../../assets/icon/cloud-computing.svg';
import { ReactComponent as IconStrategyMoneySVG } from '../../assets/icon/strategy-money.svg';

// UI
import Button from '../../components/UI/Button/Button';
import TestimonialsSlider from './UI/TestimonialsSlider';
import QuoteModal from './UI/QuoteModal';
import PortfolioModal from './UI/PortfolioModal';

const Home: React.FunctionComponent<{}> = () => {
	const [ showQuoteRequestModal, setShowQuoteRequestModal ] = React.useState<boolean>(false);
	const openQuoteRequestModal = () => setShowQuoteRequestModal(true);
	const closeQuoteRequestModal = () => setShowQuoteRequestModal(false);
	const toggleQuoteRequestModal = () => setShowQuoteRequestModal(!showQuoteRequestModal);

	const [ showQuoteRequestConfirmation, setShowQuoteRequestConfirmation ] = React.useState<boolean>(false);
	const closeQuoteRequestConfirmation = () => setShowQuoteRequestConfirmation(false);
	const openQuoteRequestConfirmation = () => setShowQuoteRequestConfirmation(true);

	const [ showPortfolioModal, setShowPortfolioModal ] = React.useState<boolean>(false);
	const openPortfolioModal = () => setShowPortfolioModal(true);
	const closePortfolioModal = () => setShowPortfolioModal(false);
	const togglePortfolioModal = () => setShowPortfolioModal(!showPortfolioModal);

	return (
		<div className={[ classes.Home, 'animated fadeIn' ].join(' ')}>
			{/* <!-- Header --> */}
			<section id='Header' className={classes.Header}>
				<div className={[ classes.TextVerticalCenter, 'text-vertical-center' ].join(' ')}>
					<div className={classes.LandingWrap}>
						<h1>
							<strong>
								One Stop <br />Technologies
							</strong>
						</h1>
						<h3>
							<em>
								Custom Software <br />Built To Last
							</em>
						</h3>

						<Button
							onClick={openQuoteRequestModal}
							className='bg-brand-blue mt-3'
							color='primary'
							variant='contained'
							size='large'
						>
							Request Free Quote
						</Button>
					</div>
				</div>
			</section>

			<QuoteModal
				showModal={showQuoteRequestModal}
				toggleModal={toggleQuoteRequestModal}
				closeModal={closeQuoteRequestModal}
				afterSuccessfulFormSubmit={openQuoteRequestConfirmation}
			/>

			<Snackbar
				className={classes.QuoteRequestConfirmationSnackbar}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				key={`topcenter`}
				open={showQuoteRequestConfirmation}
			>
				<Alert
					className={classes.QuoteRequestConfirmationAlert}
					color='success'
					isOpen={showQuoteRequestConfirmation}
					toggle={closeQuoteRequestConfirmation}
				>
					<h2 className='alert-heading color-success'>Quote Request Received!</h2>
					<hr />
					<p className='color-success text-center'>
						Hey, thanks for expressing interest in working with One Stop Technologies! We've received your
						request and will get back to you within 24 - 48 hours. In the mean time, we've sent a
						confirmation email to your provided email address. Please ensure that you've received this email
						and that it did not mistakenly go to your junk / spam folder. You won't want to miss any follow
						up communication. We're looking forward to working with you.
					</p>
				</Alert>
			</Snackbar>

			{/* <!-- About --> */}
			<section id='About' className={classes.About}>
				<div className={[ classes.AboutContainer, 'container-fluid' ].join(' ')}>
					<div className='row align-items-center'>
						<div className={[ classes.ImgNameCol, 'col-md-6' ].join(' ')}>
							<div className={classes.ImgNameWrap}>
								<div className={classes.ImgNameWrapInner}>
									<div className={classes.ImgCaption}>
										<p className={classes.Caption}>
											<b>Alex Di Vito</b> &mdash; Founder of One Stop
										</p>
									</div>
									<img
										alt='Founder of One Stop'
										className={classes.Img}
										src='/assets/img/founder.JPG'
									/>
								</div>
							</div>
						</div>

						<div className='col-md-6'>
							<p className='section-header text-uppercase  mt-4 mt-md-0'>About</p>
							<h2 className='section-subheader'>Software design &amp; development.</h2>
							<p className='lead'>
								Hi there! My name is Alex Di Vito, digital strategist, software engineer and founder
								here at One Stop Technologies. We create software for businesses that enhances their
								day-to-day operations, creates new growth opportunities and provides value for their
								customers.
							</p>
							<p className='lead'>
								We understand that a great project starts with great communication. In order to build
								the best online experience for your business, we need to understand your problems, goals
								and expectations. We work closely with you to ensure that we build the perfect system
								for your needs.
							</p>
							<p className='lead'>
								Ordinary isn’t our thing, and thanks to our 5+ years of software design and development
								experience, ordinary doesn’t have to be your thing either!
							</p>
						</div>
					</div>
				</div>
			</section>

			{/* <!-- Services --> */}
			<section id='Services' className={classes.Services}>
				<div className={[ classes.ServicesContainer, 'container-fluid' ].join(' ')}>
					<div className='row align-items-center'>
						<div className='col-md-4 offset-md-2 mt-4 mt-md-0 mb-2 mb-md-0'>
							<p className='section-header text-uppercase'>Services</p>
							<h2 className='section-subheader'>
								Become a customer magnet with software tailored to your needs.
							</h2>
							<p className='lead'>
								Having trouble standing out in your industry? Are you struggling to push past your
								current earnings plateau? That ends here! We can help. In fact, it’s kinda our thing.
							</p>
						</div>
						<div className={[ 'col-md-6 bg-brand-blue' ].join(' ')}>
							<div className={'row'}>
								<div className={'col-md-10 col-xl-8'}>
									<div className={classes.ServicesWrapper}>
										<div className={classes.ServicesSection}>
											{/* 1st Service Item */}
											<div className={classes.ServiceItem}>
												<div className={'row align-items-center justify-content-center mb-2'}>
													<div className={'col'}>
														<div className={classes.ServiceItemIcon}>
															<IconWebDesignSVG className={classes.ServiceItemIcon} />
														</div>
														<h2 className={classes.ServiceItemHeader}>
															<strong>Web Design &amp; Development</strong>
														</h2>
													</div>
												</div>
												<p className=''>
													We specialize in web and mobile applications. Whether you need an
													internal business tool or a public facing web portal for your
													customers, we can help.
												</p>
												<hr className={classes.ShortLine} />
											</div>

											{/* 3rd Service Item */}
											<div className={classes.ServiceItem}>
												<div className={'row align-items-center justify-content-center mb-2'}>
													<div className={'col'}>
														<div className={classes.ServiceItemIcon}>
															<IconCloudComputeSVG className={classes.ServiceItemIcon} />
														</div>
														<h2 className={classes.ServiceItemHeader}>
															<strong>Web Hosting &amp; Support</strong>
														</h2>
													</div>
												</div>
												<p className=''>
													Once we've got you up and running, we'll ensure you stay that way.
													We'll monitor the health of your site at all times and if there is
													ever a problem, we'll be there to fix it immediately.
												</p>
												<hr className={classes.ShortLine} />
											</div>

											{/* 2nd Service Item */}
											<div className={classes.ServiceItem}>
												<div className={'row align-items-center justify-content-center mb-2'}>
													<div className={'col'}>
														<div className={classes.ServiceItemIcon}>
															<IconDatabaseSVG className={classes.ServiceItemIcon} />
														</div>
														<h2 className={classes.ServiceItemHeader}>
															<strong>Custom Software Systems</strong>
														</h2>
													</div>
												</div>
												<p className=''>
													We want to partner with you to build something amazing. A software
													solution that can grow with your business and be customized to your
													evergrowing needs.
												</p>
												<hr className={classes.ShortLine} />
											</div>

											{/* 4th Service Item */}
											<div className={classes.ServiceItem}>
												<div className={'row align-items-center justify-content-center mb-2'}>
													<div className={'col'}>
														<div className={classes.ServiceItemIcon}>
															<IconStrategyMoneySVG className={classes.ServiceItemIcon} />
														</div>
														<h2 className={classes.ServiceItemHeader}>
															<strong>Brand Strategy</strong>
														</h2>
													</div>
												</div>
												<p className=''>
													We will work with you to understand how your products and services
													are currently perceived in the market. We'll analyze your
													competitors and create a branding strategy that will differentiate
													you from the competition.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <!-- Portfolio --> */}
			<section id='Portfolio' className={classes.Portfolio}>
				<div className={[ classes.PortfolioContainer, 'container-fluid' ].join(' ')}>
					<div className='row align-items-center'>
						<div className='col-md-4 offset-md-2 mt-4 mt-md-0 mb-2 mb-md-0'>
							<p className='section-header text-uppercase'>Portfolio</p>
							<h2 className='section-subheader'>
								We see projects as partnerships and genuinely care about our clients.
							</h2>

							<Button
								onClick={openPortfolioModal}
								className='bg-brand-blue mt-1 mb-3'
								color='primary'
								variant='contained'
								size='large'
							>
								View Our Work
							</Button>
						</div>
						<div className={[ classes.ImgPortfolioCol, 'col-md-6' ].join(' ')} />
					</div>
				</div>
			</section>

			<PortfolioModal
				toggleModal={togglePortfolioModal}
				showModal={showPortfolioModal}
				closeModal={closePortfolioModal}
			/>

			{/* Call To Action */}
			<section id='CallToAction' className={[ classes.CallToAction, 'bg-brand-blue' ].join(' ')}>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12 text-center'>
							<div className={classes.CallToActionWrapper}>
								<h3 className={[ classes.CallToActionHeader, 'text-white' ].join(' ')}>
									Have a great idea for a website or mobile application? Let's discuss the details and
									provide you with a free quote!
								</h3>

								<Button
									onClick={openQuoteRequestModal}
									className='color-black bg-white mt-2'
									color='primary'
									variant='contained'
									size='large'
								>
									Request Free Quote
								</Button>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Testimonials */}
			<section id='Testimonials' className={[ classes.Testimonials ].join(' ')}>
				<div className={[ classes.TestimonialsContainer, 'container-fluid' ].join(' ')}>
					<div className='row align-items-center'>
						<div className='col-md-5 col-lg-4 pl-md-5 order-md-2 pb-4 pb-md-0'>
							<p className='section-header text-uppercase text-md-right mt-4'>Testimonials</p>
							<h2 className='section-subheader'>Check Out What Are Customers Think.</h2>
							<TestimonialsSlider />
						</div>
						<div className={[ classes.ImgTestimonialsCol, 'col-md-6', 'order-md-1' ].join(' ')} />
					</div>
				</div>
			</section>
		</div>
	);
};

export default Home;
