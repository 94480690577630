import * as React from 'react';
import * as actions from './store/actions';
import { Route, Switch, withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Dispatch, Action } from 'redux';
import { AppDataStore } from './store/reducers/app-data';
import { connect } from 'react-redux';
import credentials from './credentials';

// containers
import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';

interface AppProps extends RouteComponentProps {}

interface AppReduxProps extends AppProps {
	appData: AppDataStore;
	currentUser: any;
	authLoading: boolean;
	onAuthStart: () => void;
	onAuthSignOut: () => void;
	onAuthSuccess: (user: any) => void;
	onAuthFail: (error: any) => void;
	onGetAppDataStart: () => void;
	onGetAppDataSuccess: (appData: any) => void;
	onGetAppDataFail: (error: any) => void;
}

class App extends React.Component<AppReduxProps, {}> {
	constructor(props: AppReduxProps) {
		super(props);
	}

	async componentDidMount() {
		// ensure authenticated session
		try {
			this.props.onAuthStart();
			const res = await Auth.currentAuthenticatedUser();
			this.props.onAuthSuccess(res);
		} catch (err) {
			// login as guest if not authenticated
			const guest = await Auth.signIn(credentials.guestUsername, credentials.guestPassword);
			console.log('guest: ', guest);

			this.props.onAuthSuccess(guest);
		}

		// query for app data
		// try {
		//   this.props.onGetAppDataStart();
		//   const res: any = await API.graphql(graphqlOperation(queries.listApps));
		//   console.log("res:", res);

		//   const appData = res.data.listApps.items[0];
		//   this.props.onGetAppDataSuccess(appData);
		// } catch (err) {
		//   // todo, send me an email
		//   this.props.onGetAppDataFail(err);
		// }
	}

	render() {
		return (
			<Layout>
				<Switch>
					{/* Static Pages */}
					<Route exact path='/' component={Home} />
					{/* <Redirect to='/' /> */}
				</Switch>
			</Layout>
		);
	}
}

function mapStateToProps(rootState: any) {
	return {
		currentUser: rootState.auth.user,
		authLoading: rootState.auth.loading,
		appData: rootState.appData
	};
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
	return {
		onAuthStart: () => dispatch(actions.authStart()),
		onAuthSignOut: () => dispatch(actions.authSignOut()),
		onAuthSuccess: (user: any) => dispatch(actions.authSuccess(user)),
		onAuthFail: (error: any) => dispatch(actions.authFail(error)),
		onGetAppDataStart: () => dispatch(actions.appDataGetStart()),
		onGetAppDataSuccess: (appData: any) => dispatch(actions.appDataGetSuccess(appData)),
		onGetAppDataFail: (error: any) => dispatch(actions.appDataGetFail(error))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App) as any);
