import React from 'react';
import { connect } from 'react-redux';
import { AppDataStore } from '../../../store/reducers/app-data';

const classes: any = require('./Footer.module.scss');

interface FooterReduxProps {
	appData: AppDataStore;
}

class Footer extends React.Component<FooterReduxProps, {}> {
	render(): JSX.Element {
		return (
			<footer className={[ classes.Footer ].join(' ')}>
				<div className='container'>
					<div className='row align-items-center justify-content-center'>
						<div className='col-12 text-center mb-4'>
							<a className='link-brand' href='mailto:info@onestoptechnologies.ca'>
								info@onestoptechnologies.ca
							</a>
						</div>
						<div className='col-12'>
							<div className={classes.SocialRow}>
								<ul className=''>
									<li>
										<a target='_blank' href='https://twitter.com/AlexDiVito__'>
											<span className='fa-stack fa-2x'>
												<i className='fas fa-square color-white fa-stack-2x' />
												<i className='fab fa-twitter color-brand-blue fa-stack-1x fa-inverse' />
											</span>
										</a>
									</li>
									<li>
										<a target='_blank' href='https://www.linkedin.com/in/alex-divito/'>
											<span className='fa-stack fa-2x'>
												<i className='fas fa-square color-white fa-stack-2x' />
												<i className='fab fa-linkedin color-brand-blue fa-stack-1x fa-inverse' />
											</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

function mapStateToProps(rootState: any) {
	return {
		appData: rootState.appData
	};
}

export default connect(mapStateToProps)(Footer);
