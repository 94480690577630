import * as React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import PortfolioSlider from './PortfolioSlider';

const ClassNames: any = require('./index.module.scss');

interface PortfolioModalProps {
	showModal: boolean;
	closeModal: () => void;
	toggleModal: () => void;
}

const PortfolioModal: React.FC<PortfolioModalProps> = (props) => {
	const { showModal, closeModal, toggleModal } = props;

	return (
		<Modal className={ClassNames.PortfolioModal} isOpen={showModal} toggle={toggleModal} backdrop={true}>
			{/* <ModalHeader className={ClassNames.PortfolioModalHeader} toggle={toggleModal}>
				<p className='section-header text-uppercase mb-0'>Portfolio</p>
				<span className='section-subheader'>Our Clients</span>
			</ModalHeader> */}
			<ModalBody className={[ 'container', ClassNames.PortfolioModalBody ].join(' ')}>
				<PortfolioSlider />
			</ModalBody>
		</Modal>
	);
};

export default PortfolioModal;
