import React, { Component } from 'react';

import MainNavBar from '../../components/Navigation/MainNavBar/MainNavBar';
import Footer from '../../components/Navigation/Footer/Footer';

class Layout extends Component {
	render() {
		return (
			<React.Fragment>
				<MainNavBar />

				<main>{this.props.children}</main>

				<Footer />
			</React.Fragment>
		);
	}
}

export default Layout;
